@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

code {
  font-family: 'Montserrat', sans-serif;
}



/* .App {
  text-align: center;
}

.home {
  padding: 3rem;
}

.top-nav .nav-item:hover {
  opacity: 0.5;
} */

/* .footer-icons {
  
} */

/* .insta-btn {
  cursor: pointer;
  text-decoration: none;
  background: none;
  padding-right: 1rem;
}

.insta-btn:hover {
  opacity: 0.5;
}

.linkedin-btn {
  cursor: pointer;
  text-decoration: none;
  background: none;
}

.linkedin-btn:hover {
  opacity: 0.5;
}

.jewel-img{
  background: none;
}

.jewel-img:hover {
  opacity: 0.5;
}

.jessica_2013:hover {
  opacity: 0.5;
} */

/* .nav-container {
  
} */

/* .top-nav {
  margin: 2rem;
}

.nav-item {
  text-decoration: none;
  padding: 1rem;
  
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid black;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.eye-logo {
  position: absolute;
  right: 0;
  top: 1;
}  */

/* Contact form */

/* .contact-container {
  
} */

/* .contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
  
} */

/* .form-field {
  margin: 1rem;
  border-radius: 1rem;
  width: 20rem;
  height: 2rem;
} */

/* .form-how {
  width: 20rem;
  height: 2rem;
  margin: 2rem;
} */

/* .submit-btn {
  width: 10rem;
  color: white;
  font-weight: 100;
  font-size: 1.5rem;
  line-height: 3rem;
  text-align: center;
  background-color: gray;;
  border-radius: 1rem;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.submit-btn:hover{
  opacity: 0.5;
}
 */


 
